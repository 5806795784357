import React, { FunctionComponent, ReactNode } from 'react'
import { Document, BLOCKS, MARKS } from '@contentful/rich-text-types'
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import {
  Headline2,
  Headline3,
  Headline4,
  Paragraph,
  Spacing,
} from '@boxine/tonies-ui'
import { Styleable } from '@boxine/tonies-ui/dest/types/styles/Styleable'
import { Strong } from '../RichText/components/Strong'

// SeoText RTE (H2, B[strong] and [Paragraph] is Enabled)
// https://app.contentful.com/spaces/l6vo76cztcax/content_types/seoTextComponent/fields
const OPTIONS: Options = {
  renderMark: {
    [MARKS.BOLD]: children => <Strong>{children}</Strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: unknown, children: ReactNode) => (
      <Spacing pb="spacing-l">
        <Paragraph color="normalgrey" size={2} align="center">
          {children}
        </Paragraph>
      </Spacing>
    ),
    [BLOCKS.HEADING_2]: (_node: unknown, children: ReactNode) => (
      <Headline2 color="normalgrey" align="center">
        {children}
      </Headline2>
    ),
    [BLOCKS.HEADING_3]: (_node: unknown, children: ReactNode) => (
      <Headline3 color="normalgrey" align="center">
        {children}
      </Headline3>
    ),
    [BLOCKS.HEADING_4]: (_node: unknown, children: ReactNode) => (
      <Headline4 color="normalgrey" align="center">
        {children}
      </Headline4>
    ),
  },
}

interface SeoTextProps {
  document: Document
}

const SeoText: FunctionComponent<SeoTextProps & Styleable> = ({
  document,
  className,
}) => (
  <div className={className}>
    {documentToReactComponents(document, OPTIONS)}
  </div>
)

export { SeoText }
