import React from 'react'
import { useTranslation } from 'next-i18next'
import {
  Button,
  Headline4,
  Headline5,
  Paragraph,
  Spacing,
  useMedia,
} from '@boxine/tonies-ui'
import { GenericImageText } from '..'
import { useDateFormatter } from '../../../../hooks/useDateFormatter'
import { BlogPostLayoutSchema } from '../../../../lib/contentful/datamodel/layouts'
import { Accent } from '@/tonies-ui/atoms/Accent'
import { stripTags } from '../../../../utils/stripTags'
import { useGtmV4 } from '../../../../providers/gtmV4'
import { useTheme } from 'styled-components'

export type BlogTeaser = Pick<
  BlogPostLayoutSchema,
  | 'author'
  | 'categoryLabel'
  | 'date'
  | 'imageDesktop'
  | 'imageMobile'
  | 'pageMetaData'
  | 'tagLabels'
  | 'title'
>

export const BlogPostTeasers = ({ entries }: { entries: BlogTeaser[] }) => {
  const { t } = useTranslation()
  const { pushGtmV4Event } = useGtmV4()
  const df = useDateFormatter()
  const theme = useTheme()
  const isMobile = useMedia({
    maxWidth: theme.screenTabletL,
  })

  return (
    <>
      {entries.map((p, i) => (
        <GenericImageText
          key={p.title + i}
          media={{
            variant: 'image',
            alt: stripTags(p.title),
            src: isMobile ? p.imageMobile.file.url : p.imageDesktop.file.url,
            ratio: [3, 2],
            borderRadius: ['xs'],
            link: {
              href: p.pageMetaData.slug || '',
            },
            onClick: () =>
              pushGtmV4Event({
                eventType: 'selectContent',
                type: 'image',
                ctaLabel: '[coded] BlogTeaser (list) Image',
                ctaLink: p.pageMetaData.slug || '',
              }),
          }}
          orientation="vertical"
        >
          <Spacing my="spacing-xs">
            <Headline4>
              <Accent text={p.title} />
            </Headline4>
          </Spacing>
          <Spacing mb="spacing-xs">
            <Paragraph size={4}>
              {p.author
                ? t('blog:publishedOnAndBy', {
                    date: df(new Date(p.date)),
                    name: p.author.name,
                  })
                : t('blog:publishedOn', {
                    date: df(new Date(p.date)),
                  })}
            </Paragraph>
          </Spacing>
          <Button
            as="a"
            variant="secondary"
            href={p.pageMetaData.slug}
            onClick={() =>
              pushGtmV4Event({
                eventType: 'selectContent',
                type: 'secondary',
                ctaLabel: '[coded] BlogTeaser (list) Button',
                ctaLink: p.pageMetaData.slug || '',
              })
            }
          >
            {t('blog:readMore')}
          </Button>
        </GenericImageText>
      ))}
    </>
  )
}

export const BlogPromotedPostTeasers = ({
  entries,
}: {
  entries: BlogTeaser[]
}) => {
  const { t } = useTranslation()
  const { pushGtmV4Event } = useGtmV4()

  return (
    <>
      {entries.map((p, i) => (
        <Spacing
          key={p.title + i}
          mb={entries.length !== i ? 'spacing-s' : undefined}
        >
          <GenericImageText
            media={{
              variant: 'image',
              alt: stripTags(p.title),
              src: p.imageMobile.file.url,
              width: '30%',
              ratio: [1, 1],
              placeholder: false,
              borderRadius: ['xs'],
              link: {
                href: p.pageMetaData.slug || '',
              },
              onClick: () =>
                pushGtmV4Event({
                  eventType: 'selectContent',
                  type: 'image',
                  ctaLabel: '[coded] BlogTeaser (promoted or related) Image',
                  ctaLink: p.pageMetaData.slug || '',
                }),
            }}
          >
            <Spacing applyToChildren mb="spacing-xs">
              <Headline5 asHTMLTag="h4">
                <Accent text={p.title} />
              </Headline5>
              <Button
                variant="secondary"
                as="a"
                href={p.pageMetaData.slug}
                onClick={() =>
                  pushGtmV4Event({
                    eventType: 'selectContent',
                    type: 'secondary',
                    ctaLabel: '[coded] BlogTeaser (promoted or related) Button',
                    ctaLink: p.pageMetaData.slug || '',
                  })
                }
              >
                {t('blog:readMore')}
              </Button>
            </Spacing>
          </GenericImageText>
        </Spacing>
      ))}
    </>
  )
}
