import React, {
  useCallback,
  useState,
  createRef,
  FunctionComponent,
} from 'react'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'
import { RichText } from '../../components/molecules/RichText'
import { Section } from '../../components/atoms/Section'
import { SeoText } from '../../components/molecules/SeoText'
import { PageLayout } from '../PageLayout'
import {
  Box,
  Button,
  CTARow,
  Grid,
  GridArea,
  OmniButton,
  Paragraph,
  Headline4,
  media,
  Spacing,
} from '@boxine/tonies-ui'
import { Tag } from '@/tonies-ui/atoms/Tag'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { MediaCopySection } from '../../components/organisms/MediaCopySection'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { useViewportConfig } from '../../tonies-ui/hooks/useViewport'
import { useAggregatedShopLocale } from '../../providers/aggregatedShopLocale'
import { BlogPromotedPostTeasers } from '../../components/molecules/GenericImageText/variants/BlogTeaser'
import { GenericImageText } from '../../components/molecules/GenericImageText'
import { SocialMediaLinks } from '../../components/molecules/SocialMediaLinks'
import { SimpleLink } from '@/tonies-ui/helpers/css-helper'
import { stripTags } from '../../utils/stripTags'
import { BlogPostPage } from '../../pages/[locale]/blog/[slug]/index.page'
import MultiClamp, { MultiClampHTMLDivElement } from 'react-multi-clamp'

export const StickySidebar = styled.div`
  ${media.laptop`
    position: sticky;
    top: 6rem;
  `}
`

const StyledButton = styled(Button)`
  font: inherit;
`

export const BlogPostLayout: FunctionComponent<
  Aggregated<BlogPostPage['page']>
> = ({
  pageMetaData,
  abstract,
  author,
  categoryLabel,
  content,
  date,
  imageDesktop,
  imageMobile,
  seoText,
  tagLabels,
  title,
  relatedPostTeasers,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const df = useDateFormatter()
  const {
    commonPages: { blog },
  } = useAggregatedShopLocale()

  const config = useViewportConfig({
    desktop: {
      columns: 12,
      content: { col: { width: 8, start: 0 }, row: { start: 0 } },
      sideBar: { col: { width: 4, start: 8 }, row: { start: 0 } },
    },
    tablet: {
      columns: 2,
      content: { col: { width: 2 }, row: { start: 0 } },
      sideBar: { col: { width: 1 }, row: { start: 1 } },
    },
    mobile: {
      columns: 1,
      content: { col: { width: 1 }, row: { start: 0 } },
      sideBar: { col: { width: 1 }, row: { start: 1 } },
    },
  })

  const ref = createRef<MultiClampHTMLDivElement>()
  const removeClamp = useCallback(() => {
    ref.current?.multiClamp.reload({
      clamp: 'auto',
      useOriginalText: true,
    })
  }, [ref])

  const relatedPostTeasersMax = 5
  const [hasShowMoreRelatedPosts, setHasShowMoreRelatedPosts] = useState(
    relatedPostTeasers && relatedPostTeasers.length > relatedPostTeasersMax
  )

  return (
    <PageLayout
      meta={{
        ...pageMetaData,
        openGraphImage: {
          title: title,
          file: {
            url: imageDesktop.file.url,
            details: {
              image: {
                width: 0,
                height: 0,
              },
              size: 0,
            },
            fileName: '',
            contentType: '',
          },
        },
        blogPost: {
          author,
          date,
        },
      }}
    >
      <MediaCopySection
        hasMarginTop
        headline={{
          variant: 'Headline1',
          children: title,
        }}
        text={{ children: abstract }}
        direction="ltr"
        media={{
          mobile: {
            variant: 'image',
            alt: title,
            src: imageMobile.file.url,
          },
          desktop: {
            variant: 'image',
            alt: title,
            src: imageDesktop.file.url,
          },
        }}
        backgroundColor={colors['lightblue-20']}
        layout="half-screen"
        referenceTitle={`MediaCopy - ${stripTags(title)}`}
      >
        <Spacing my="spacing-xxs">
          <SimpleLink href={blog?.slug + `?category=${categoryLabel.label}`}>
            <Tag mb="spacing-s" mr="spacing-xs">
              {categoryLabel.label}
            </Tag>
          </SimpleLink>
          {tagLabels &&
            tagLabels.map(({ label }) => (
              <SimpleLink key={label} href={blog?.slug + `?tag=${label}`}>
                <Tag mb="spacing-s" mr="spacing-xs">
                  {label}
                </Tag>
              </SimpleLink>
            ))}
        </Spacing>
        <Paragraph size={4}>
          {author
            ? t('blog:publishedOnAndBy', {
                date: df(new Date(date)),
                name: author.name,
              })
            : t('blog:publishedOn', {
                date: df(new Date(date)),
              })}
        </Paragraph>
      </MediaCopySection>
      <Section>
        <Grid columns={config.columns} hasGapH hasGapV>
          <GridArea
            colStart={config.content.col.start}
            colWidth={config.content.col.width}
            rowStart={config.content?.row.start}
          >
            <Spacing mb="spacing-m">
              <OmniButton
                href={blog?.slug}
                isBackLink
                hideBackLinkTextOnMobile={false}
              >
                {t('blog:toOverview')}
              </OmniButton>
            </Spacing>
            <RichText document={content} childrenPaddingBottom="spacing-m" />
            <Spacing my="spacing-xxs">
              <OmniButton
                href={blog?.slug}
                isBackLink
                hideBackLinkTextOnMobile={false}
              >
                {t('blog:toOverview')}
              </OmniButton>
            </Spacing>
          </GridArea>
          <GridArea
            colStart={config.sideBar.col.start}
            colWidth={config.sideBar.col.width}
            rowStart={config.sideBar.row.start}
          >
            <StickySidebar>
              {relatedPostTeasers && relatedPostTeasers.length > 0 && (
                <Spacing mb="spacing-m">
                  <Box borderColor="lightgrey" borderRadius={['s']}>
                    <Box
                      px="spacing-s"
                      py="spacing-xs"
                      backgroundColor="lightergrey"
                    >
                      <Headline4>{t('blog:relatedPosts.headline')}</Headline4>
                    </Box>
                    <Box pl="spacing-s" pt="spacing-s" pr="spacing-s">
                      <BlogPromotedPostTeasers
                        entries={
                          hasShowMoreRelatedPosts
                            ? relatedPostTeasers.slice(0, relatedPostTeasersMax)
                            : relatedPostTeasers
                        }
                      />
                    </Box>
                    {hasShowMoreRelatedPosts && (
                      <Box
                        px="spacing-s"
                        py="spacing-xs"
                        backgroundColor="lightergrey"
                      >
                        <CTARow justifyContent="center">
                          <Button
                            variant="secondary"
                            onClick={() => setHasShowMoreRelatedPosts(false)}
                          >
                            {t('blog:loadMore')}
                          </Button>
                        </CTARow>
                      </Box>
                    )}
                  </Box>
                </Spacing>
              )}
              {author && (
                <Spacing mb="spacing-m">
                  <Box borderColor="lightgrey" borderRadius={['s']}>
                    <Box
                      px="spacing-s"
                      py="spacing-xs"
                      backgroundColor="lightergrey"
                    >
                      <Headline4>
                        {t('blog:aboutAuthor.headline', {
                          name: author.name,
                        })}
                      </Headline4>
                    </Box>
                    <Box pl="spacing-s" pt="spacing-s" pr="spacing-s">
                      <GenericImageText
                        media={{
                          variant: 'image',
                          alt: author.name,
                          src: author.image.file.url,
                          width: '30%',
                          ratio: [1, 1],
                          borderRadius: ['xs'],
                        }}
                      >
                        <Spacing applyToChildren mb="spacing-s">
                          <Paragraph size={2} asHTMLTag="div">
                            <MultiClamp
                              clamp={5}
                              ellipsis={
                                <span>
                                  ...{' '}
                                  <StyledButton
                                    variant="secondary"
                                    onClick={removeClamp}
                                  >
                                    {t('blog:aboutAuthor.showMore')}
                                  </StyledButton>
                                </span>
                              }
                              splitByWords
                              ref={ref}
                            >
                              {author.description}
                            </MultiClamp>
                          </Paragraph>
                        </Spacing>
                      </GenericImageText>
                    </Box>
                  </Box>
                </Spacing>
              )}
              <Spacing mb="spacing-m">
                <Box
                  backgroundColor="white"
                  borderColor="lightgrey"
                  borderRadius={['s']}
                >
                  <Box
                    px="spacing-s"
                    py="spacing-xs"
                    backgroundColor="lightergrey"
                  >
                    <Headline4>{t('blog:socialMedia.headline')}</Headline4>
                  </Box>
                  <Box p="spacing-s">
                    <SocialMediaLinks iconSizeInEm={2} iconGapInEm={1} />
                  </Box>
                </Box>
              </Spacing>
            </StickySidebar>
          </GridArea>
        </Grid>
        {seoText?.content && <SeoText document={seoText.content} />}
      </Section>
    </PageLayout>
  )
}
